<template>
  <div class="box-homefood" ref="homeRef">
    <header>
      <foodHeader />
    </header>
    <div class="maps">
      <div class="baiduMapzuob">
        <div class="list">
          <van-tabs v-model:active="active" type="card" @click-tab="onClickTab">
            <van-tab v-for="(item,index) in citys" :key="index" :title="item" :name="item"></van-tab>
          </van-tabs>
        </div>
      </div>
      <div class="baiduMpasdemo" v-if="baidulits.length>0">
        <BaiduMap ref="RefChilde" :baiduMapIps="baiduMapIps" :baidulits="baidulits" :entelist="entelist" /> 
      </div>
      <div class="baidumap-html" ref="homeListRef">
        <div class="home-html">
          <div class="home-html-title" style="width:100%;">学校列表</div>
          <div :class="scrollTop>9?'html-lists':'html-list'" :style="{overflow:scrollTop>40?'auto':'hidden'}">
            <div class="list-demo" v-for="(item,index) in entelist" :key="index" >
              <div class="demo-title">
                <div>{{item.retitle}}</div>
                <div @click="mapsclicks(item)">查看地图</div>
              </div>
              <div class="demo-bottoms">
                <!-- <div class="conet-top" @click="mapsclicks()">
                  <div class="top-left">
                    <img :src="item.repic" alt="" srcset="">
                  </div>
                  <div class="top-right">
                    老北京烤鸭，是一家以全鸭为主的餐厅。店家的鸭做得特别地道：麻食，这道菜的名字很有趣，你一定想像不出是用什么食材做的。其实这是一种主食，跟凉粉差不多的。而且是店家手工做的，很特别。吃起来辣辣的，却感觉很清爽。脆皮包饼，这道菜是用市场上最新鲜最肥美的鸭烤制而成，皮脆肉嫩，吃时店家大厨将鸭皮当场片下来，提供脆皮给食客包饼，吃这前一定要醮上店家特制的酱料，入口又脆又甜，满口留香。烤鸭，店家用从市场选来的新鲜全鸭现场烤制，刷上店家自炸的香油，待鸭皮呈金黄色时取出。厨师会帮食客片好。品尝起肉特别嫩，店家用种吃法是可以沾点糖吃，味道鲜甜回味无穷。店家大厅内的装潢是以红白色为主，简约淡雅。店内有靠窗位，晚上吃饭时还能欣赏到街上的车水马龙，很是惬意。
                  </div>
                </div> -->
                <div class="conet-adddres">地址：{{item.readdress}}</div>
                <div class="conet-bottom">
                  <!-- <ul>
                    <li v-for="(item,index) in 4" :key="index">
                      <van-button :icon="kanImg" type="primary" size="mini">厨房</van-button>
                    </li>
                  </ul> -->
                  <div class="ul-right">
                    <router-link :to="'/vidoList/'+item.pkguid">
                      <button class="tzc_ul_button">视频查看</button>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <van-pagination class="tzc_paging" v-model="currentPage" :page-count="total" mode="simple">
            <template #prev-text>
              <span @click="sPaging(item)">上一页</span>
            </template>
            <template #next-text>
              <span @click="xPaging(item)">下一页</span>
            </template>
          </van-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import foodHeader from "@/components/foodHeader.vue";
import BaiduMap from "@/components/BaiduMap.vue";
import dianImgs from "@/assets/images/kaoya.jpg";
import kanImg from "@/assets/images/jiankong.png";
import { defineComponent, ref, onMounted } from "vue";
import {com_list,com_cam_list,com_cam_list_url,comcity_cam} from '@/api/index';
import {List,Pagination} from 'vant';
export default defineComponent({
  name: "homeFood",
  setup() {
    let RefChilde = ref();
    let homeRef = ref(); //顶部
    let scrollTop = ref();
    let homeListRef = ref(); //需要就算高度的div
    let baiduMapIps = ref("112.754994,37.720477");
    let baidulits=ref([]);//定位点集合
    let citys = ref([]); //县级市集合
    let active = ref(0); //县市选择
    let entelist= ref();//企业列表集合
    const currentPage = ref(1);//当前页码
    let total=ref();//总页码
    let equery={
      city:'',
      page:1,
      pagesize:6,
    };
    const C_itys=()=>{
      let query={
        type:'ary'
      }
      comcity_cam(query).then((res) => {
        citys.value=res.data.mydata.names;
        console.log("数据测试",citys)
      }).catch((err) => {
        console.log(err)
      });
    };
    // 选项卡点击方法
    const onClickTab = ({ title }) =>{
      equery.page=1;
      currentPage.value=1;
      equery.city=title;
      //vue3向子组件使用props传值（两个值，若其中不用的值执行方法前清空，则子组件数据会重新渲染），值清空，子组件会重新渲染
      baidulits.value=[];
      enterpriseL();
    };
    // 企业列表方法
    const enterpriseL=()=>{
      com_list(equery).then((res) => {
        entelist.value=res.data.mydata.data;
        total.value=res.data.mydata.pageall;
        baidulits.value=[];
        for (let i = 0; i < entelist.value.length; i++) {
          baidulits.value=baidulits.value.concat(entelist.value[i].readdressgd);
        }
      }).catch((err) => {
        console.log(err)
      });
    };
    // 分页点击方法
    // 上一页
    const sPaging=()=>{
      // equery.page='';
      baidulits.value=[];
      equery.page-=1;
      if (equery.page==1) {
        equery.page=1
      }
      enterpriseL();
    };
    // 下一页
    const xPaging=()=>{
      // equery.page='';
      equery.page+=1;
      baidulits.value=[];
      enterpriseL();
      // RefChilde.value.mapMarkersadd();
    };

    let mapsclicks = (data) => {
      // enterpriseL();
      console.log("dt",data)
      if (scrollTop.value > 40) {
        baiduMapIps.value = data;
        RefChilde.value.sonFn(baiduMapIps.value);
      }
    };
    // 获取滚动条的位置
    let windowScroll = () => {
      scrollTop.value =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // console.log(scrollTop.value )
    };
    onMounted(() => {
      window.addEventListener("scroll", windowScroll);
    });
    
    return {
      dianImgs,
      kanImg,
      mapsclicks,
      baiduMapIps,
      baidulits,
      RefChilde,
      homeRef,
      homeListRef,
      scrollTop,
      C_itys,//城市列表方法
      citys,//城市列表
      active,//选项卡
      onClickTab,//选项卡方法
      entelist,//企业列表
      enterpriseL,//企业列表方法
      total,//总页码
      sPaging,//分页方法
      xPaging,
      equery,
      currentPage
    };
  },
  mounted(){
    this.C_itys();
    this.enterpriseL();
  },
  components: {
    'van-pagination':Pagination,
    'van-list':List,
    foodHeader,
    BaiduMap,
  },
});
</script>
<style scoped lang="less">
.html-list {
  margin-top: 0.4rem;
  width: 100%;
  height: 49.5vh;
  // background: red;
  // overflow: hidden;
  opacity: 0.6;
  filter: blur(2px);
}
.html-lists {
  margin-top: 0.4rem;
  width: 100%;
  height: 49.5vh;
  // background: red;
  // overflow: auto;
  opacity: 1;
  filter: blur(0px);
  // background: white;
  animation: 1.2s opacity2;
}
@keyframes opacity2 {
  0% {
    opacity: 0.6;
    filter: blur(2px);
  }
  45% {
    opacity: 0.8;
    filter: blur(1px);
  }
  75% {
    opacity: 0.9;
    filter: blur(0.5px);
  }
  100% {
    opacity: 1;
    filter: blur(0px);
  }
}
.list-demo {
  width: 100%;
  // height: 30vh;
  // height: 3rem;
  margin-bottom: 0.6rem;
  .demo-title {
    width: 100%;
    font-size: 0.9rem;
    box-sizing: border-box;
    padding: 0.5rem 0.7rem;
    letter-spacing: 0.06rem;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background: #1281e0;
    color: white;
    display: flex;
    div:nth-child(1){
      width: 70%;
      font-weight: bold;
    }
    div:nth-child(2){
      width: 30%;
      text-align: right;
      
    }
  }
  .demo-bottoms {
    width: 99.4%;
    border: 1px solid #1281e0;
    // height: 10rem;
    padding-bottom: 0.4rem;
    border-top: 0px;
    .conet-top {
      width: 100%;
      height: 6rem;
      // background: red;
      display: flex;
      box-sizing: border-box;
      padding: 0.4rem 0.45rem;
      .top-left {
        width: 40%;
        height: 100%;
        overflow: hidden;
        border-radius: 3px;
        img {
          width: 100%;
          height: auto;
        }
      }
      .top-right {
        width: 60%;
        height: 100%;
        box-sizing: border-box;
        padding-left: 0.55rem;
        // padding-top: 0.2rem;
        font-size: 0.9rem;
        color: #4e4e4e;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        display: -moz-box;
        -moz-line-clamp: 4;
        -moz-box-orient: vertical;
        word-wrap: break-word;
        word-break: break-all;
        white-space: normal;
        line-height: 1.37rem;
        text-indent: 2em;
      }
    }
    .conet-bottom {
      width: 100%;
      height: 1.5rem;
      // display: flex;
      margin-top: 0.3rem;
      ul {
        width: 85%;
        box-sizing: border-box;
        padding: 0 0.5rem;
        overflow: hidden;
        padding-top: 0.3rem;
        display: flex;
        li {
          font-size: 0.9rem;
          text-align: center;
          margin-right: 0.5rem;
        }
      }
      .ul-right {
        // padding: 0.5rem 0;
        width: 20%;
        float: right;
        text-align: left;
        color: #1281e0;
        a {
          font-size: 0.7rem;
          .tzc_ul_button{
            background: #1281e0;
            color: white;
            border: none;
            padding: 0.3rem;
            border-radius: 5px;
          }
        }
      }
    }

    .conet-adddres {
      width: 100%;
      box-sizing: border-box;
      padding: 0 0.5rem;
      font-size: 0.9rem;
      color: #313131;
    }
  }
}
/deep/ .van-button--primary {
  color: #4e4e4e;
  background-color: white;
  border: var(--van-button-border-width) solid
    var(--van-button-primary-border-color);
}
</style>
<style scoped lang="less">
.box-homefood {
  width: 100%;
  overflow-x: hidden;
  .maps {
    width: 100%;
  }
}
header {
  width: 100%;
  position: fixed;
  top: 0px;
  z-index: 999999;
}
.baiduMpasdemo {
  position: fixed;
  top: 3.5rem;
  width: 100%;
  height: 88vh;
  overflow: hidden;
}

.baidumap-html {
  position: absolute;
  opacity: 1;
  width: 100%;
  height: 67vh;
  top: 75vh;
  .home-html {
    width: 100%;
    margin: 0 auto;
    height: 110%;
    box-sizing: border-box;
    padding: 0 0.5rem;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    background: white;
    box-shadow: 0px -2px 10px 0px rgba(212, 212, 212, 0.75);

    .home-html-title {
      width: 100%;
      border-bottom: 1px solid #e2e2e2;
      box-sizing: border-box;
      padding: 0.6rem 0.2rem;
      font-size: 0.93rem;
      font-weight: bold;
      color: #4e4e4e;
    }
    .tzc_paging{
      // margin-bottom: 2rem;
    }
  }
}
.baiduMapzuob {
  position: fixed;
  top: 4.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 999;
  .list {
    width: 90%;
  }
}
/deep/.van-tab {
  width: 4.7rem;
  // line-height:40px;
}
/deep/ .van-tabs__nav--card {
  margin: 0 0px;
}
/deep/ .van-tabs__nav--complete {
  padding-right: 0px;
  padding-left: 0px;
}
/deep/ .van-tabs__nav--card .van-tab {
  border-right: 1px solid #1281e0;
}
/deep/ .van-tabs__nav--card .van-tab.van-tab--active {
  background-color: #1281e0;
}
/deep/ .van-tabs__nav--card {
  border: 1px solid #1281e0;
}
/deep/ .van-tab {
  color: #1281e0;
}
/deep/ .van-tab__text {
  margin-top: 0.1rem;
}
</style>